function getEnvVar(envVarStr) {

// eslint-disable-next-line
const isProd = process.env.NODE_ENV === 'production'
const envVar = envVarStr.replace(/[{}]/g, '')
// eslint-disable-next-line
return  process.env[envVar];
}
export const REACT_APP_RPM_ENV = getEnvVar('{REACT_APP_RPM_ENV}');
export const REACT_APP_RPM_IDP = getEnvVar('{REACT_APP_RPM_IDP}');
export const REACT_APP_RPM_BROKER = getEnvVar('{REACT_APP_RPM_BROKER}');