import { getToken } from '../../adal';
import {REACT_APP_RPM_IDP} from '../../envVars';
import * as JWT from 'jwt-decode';
import { IdpToken } from '../models/idp-token';

const adalConfig = {
    tenant: 'BEFCF223-5020-41CC-AFC1-AA4B72D5A550',
    clientId: '54656803-9C87-44AA-BE09-FE56E117734E',
    endpoints: {
      api: 'BEFCF223-5020-41CC-AFC1-AA4B72D5A550'
    },
    //navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
};

const header = { 'Authorization': "Basic ".concat(btoa(adalConfig.clientId.concat(":"))), 'Content-Type' : 'application/x-www-form-urlencoded' }

const idp = REACT_APP_RPM_IDP || 'http://localhost:7000/';

const idpUrl = idp + 'connect/token';

const getIdpToken = function(): Promise<any>{
    const token: string = getToken();

    const postBody = "grant_type=aad&access_token=".concat(token)

    const payloadTask = new Promise<any>((resolve, reject) => {
      const httpTask = fetch(idpUrl,{method: 'POST', headers: header, body: postBody})
      .then(function(response) {
        if (response.status !== 200) {
          console.error(idpUrl + ': ' + response.status);
          return response.text().then(function(text) {
            console.error(text);
          });
        }
        return response.json();
      })
      .then(function(data) {
        if(data && data.access_token){
          // Decode token to acquire expiration time of RPM IDP token
          const tokenData = JWT.default<IdpToken>(data.access_token);
          if(tokenData.exp){
            localStorage.setItem("idp.token-refresh",tokenData.exp.toString());
          }
          localStorage.setItem("idp.token",data.access_token);
          resolve(data.access_token);
        }else{
          return reject('No Token Data');
        }
      });
    
    httpTask.catch(ex => {
      console.error(ex);
      reject(ex);
    });
     
  })

  return payloadTask;
    
}


const tokenExpiring = function(): boolean{
  const tokenExpireString = localStorage.getItem("idp.token-refresh");
  if(tokenExpireString){
    const tokenExpire = parseInt(tokenExpireString);
    const now = (new Date()).valueOf() / 1000;
    if (tokenExpire < (now+300)){
      console.log("Token expiring soon, refreshing");
      getIdpToken();
      return true;
    }else{
      console.log("Token expiration check, refresh not required");
      return false;
    }
  }
  // no token
  return true;

}
const tokenInterval = setInterval(tokenExpiring, 60000);


const getCachedToken = function(): string{
  let token = localStorage.getItem("idp.token");
  if(!token){
    token ="";
  }
  
  return token;
}
  
export const getRpmToken = (): Promise<any> => getIdpToken();
export const getIdpCachedToken = (): string  => getCachedToken();
  