import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { REACT_APP_RPM_ENV } from '../envVars';

// tslint:disable-next-line
declare let gtag: Function;

const TrackPageViews = (props) => {
  const location = useLocation();

  // Only in PROD do we leverage google tracking logic
  const googleTrackingCode = 'UA-145448607-4';

  useEffect(() => {
    console.log('> Router', location.pathname);

    if (REACT_APP_RPM_ENV === 'PROD') {
      if (typeof gtag === 'function') {
        gtag('config', googleTrackingCode, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          page_path: location.pathname
        });
      }
    }
  }, [location] );

  return null;
}

export default TrackPageViews;